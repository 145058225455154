<template>
  <div class="mb-1">
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <!-- <el-col :span="6">
          <placeholder :field="field" />
        </el-col> -->
        <el-col :span="6">
          <el-link
            @click="duplicateRulesModal = !duplicateRulesModal"
            :underline="false"
            type="primary"
            class="mt-1"
          >
            Duplicate rules
          </el-link>
        </el-col>
        <el-col :span="4">
          <el-link
            :underline="false"
            type="primary"
            @click="openFormRulesModal"
          >
            Form rules
          </el-link>
        </el-col>
        <el-col :span="6">
          <span>Default no.of rows open</span><br />
          <el-input-number
            size="mini"
            v-model="field.default_rows"
          ></el-input-number>
        </el-col>
        <!-- <el-col :span="6">
          <el-link
            @click=" templateRulesDialog = !templateRulesDialog"
            :underline="false"
            type="primary"
            class="mt-1"
          >
            Form rules
          </el-link>
        </el-col> -->
        <el-col :span="12" class="selection-options">
          <field-filled-by :field="field" />
          <el-select
            v-model="field.table_data_view_type"
            placeholder="Select View Type"
          >
            <el-option
              v-for="item in viewOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="field.table_data_view_type == 'Accordion_view'"
            v-model="field.selected_header_title"
            placeholder="Select header ttile"
            class="mt-1"
          >
            <el-option
              v-for="(column, index) in field.data_table_columns"
              :key="index"
              :label="column.label"
              :value="column.label"
            />
          </el-select>
          <el-checkbox
            v-if="field.table_data_view_type == 'table_view'"
            label="Allow Data Deletion"
            v-model="field.allow_deletion"
          ></el-checkbox>
          <el-checkbox
            v-if="field.table_data_view_type == 'table_view'"
            label="Allow Data Editing"
            v-model="field.allow_edit_delete"
          ></el-checkbox>
          <el-checkbox
            v-if="
              !field.card_view && field.table_data_view_type == 'Accordion_view'
            "
            label="Hide Add & Remove Buttons"
            v-model="field.hide_add_remove_buttons"
          ></el-checkbox>
        </el-col>
      </el-row>
    </el-form>

    <template
      v-if="field.data_table_columns && field.data_table_columns.length"
    >
      <el-row>
        <u>Drag Fields to Change Column order</u>
        <el-scrollbar class="elementsScroll">
          <table class="table" style="z-index: 1000; border: 10px">
            <tr>
              <draggable v-model="field.data_table_columns" tag="tr">
                <th
                  v-for="(column, index) in field.data_table_columns"
                  :key="index"
                >
                  <el-tag effect="dark">
                    {{ column.label }}
                  </el-tag>
                </th>
              </draggable>
            </tr>
          </table>
        </el-scrollbar>
      </el-row>

      <hr />
      <div>
        <div class="resizable-wrapper">
          <p>Resize Columns Below</p>
        </div>
        <div class="resizable-wrapper">
          <div class="table table-striped">
            <div
              v-for="(column, index) in field.data_table_columns"
              :key="index"
              :style="{ minWidth: getColumnWidth(column) + 'px' }"
            >
              <ResizableComponent
                :field="column"
                @resize="(data) => updateFiedSize(index, data)"
                :x="getXvalueForField(field.data_table_columns, index)"
              ></ResizableComponent>
            </div>
          </div>
        </div>
      </div>
    </template>
    <dialog-component
      :title="'Duplicate rules'"
      :visible="duplicateRulesModal"
      @before-close="duplicateRulesModal = false"
      :containerWidth="'40%'"
      :isShowFooter="false"
    >
      <duplicate-data-rules-widget
        :fields="field.data_table_columns"
        :templateData="field"
        :from_data_table="true"
      >
      </duplicate-data-rules-widget>
    </dialog-component>
    <dialog-component
      :title="'Form rules'"
      :visible="formRulesModal"
      :containerWidth="'40%'"
      @before-close="appendRules"
      :isShowFooter="false"
    >
      <template-form-rules-widget
        :allFields="
          selectedEntityFieldData && selectedEntityFieldData.data_table_columns
            ? selectedEntityFieldData.data_table_columns
            : []
        "
        :templateRules="fieldRules"
        :fromDataTable="true"
        @form-rule-added="updateFormRules"
        @form-rule-delete="deleteFormRules"
      >
      </template-form-rules-widget>
    </dialog-component>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { dataTableAllowedViewFields } from "../formComponentsView/index";
import ResizableComponent from "./ResizableComponent";
import AutomationHelper from "@/mixins/AutomationHelper";
// import TemplateFormRulesWidget from "../../widgets/TemplateFormRulesWidget.vue";
// import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
export default {
  name: "templates-formComponents-DataTable",
  mixins: [AutomationHelper],
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    // Placeholder: () => import("./Placeholder"),
    draggable,
    DuplicateDataRulesWidget: () =>
      import("../../widgets/DuplicateDataRulesWidget.vue"),

    ...dataTableAllowedViewFields,
    ResizableComponent,
    // TemplateFormRulesWidget,
    TemplateFormRulesWidget: () =>
      import("../../widgets/TemplateFormRulesWidget.vue"),
  },
  props: ["field"],
  computed: {},
  data() {
    return {
      viewOptions: [
        {
          value: "table_view",
          label: "Table",
        },
        {
          value: "card_view",
          label: "Card",
        },
        {
          value: "Accordion_view",
          label: "Accordion",
        },
      ],
      validations: [],
      headers: ["id", "name", "sport"],
      list: [
        { id: 1, name: "Abby", sport: "basket" },
        { id: 2, name: "Brooke", sport: "foot" },
        { id: 3, name: "Courtenay", sport: "volley" },
        { id: 4, name: "David", sport: "rugby" },
      ],
      duplicateRulesModal: false,
      templateRulesDialog: false,
      fieldsData: [],
      templateRules: [],
      formRulesModal: false,
      fieldRules: [],
      addDataTableStepFilters: false,
      dataTablefilters: [],
      dataTablefiltersQuery: "AND",
      selectedEntityFieldData: {},
    };
  },
  mounted() {
    this.selectedEntityFieldData = {};
    if (this.field.data_table_columns && this.field.inputType == "DATA_TABLE") {
      this.selectedEntityFieldData = { ...this.field };
    }
    this.field.table_data_view_type = "table_view";
  },
  methods: {
    handleSwitchChange() {},
    getColumnWidth(column) {
      return column.width !== undefined ? column.width : column.min_width;
    },
    saveFilters() {
      this.$set(this.field, "data_table_filters", this.dataTablefilters);
      this.$set(
        this.field,
        "data_table_filter_query",
        this.dataTablefiltersQuery
      );
      this.resetDataTableFilters();
    },
    appendRules() {
      this.field["rules"] = [...this.fieldRules];
      // this.$emit("data-table-rules-updates", this.fieldRules, this.index);
      this.fieldRules = [];
      this.formRulesModal = false;
    },
    deleteFormRules(index) {
      this.fieldRules.splice(index.index, 1);
    },
    updateFormRules(data) {
      if (data.index == -1) {
        this.fieldRules.push(data);
      } else {
        this.fieldRules[data.index] = { ...data };
      }
    },
    openFormRulesModal() {
      this.fieldRules = [...(this.field.rules || [])];
      this.formRulesModal = true;
    },
    onResize(x, y, width, height, field) {
      field.width = width;
      field.height = height;
      field.x = x;
      field.y = y;
    },
    setData() {},
    updateFiedSize(index, data) {
      if (this.field.data_table_columns[index]) {
        // Clear the existing columns before updating
        this.field.data_table_columns = [...this.field.data_table_columns];

        // Update the width and height of the specified column
        this.field.data_table_columns[index].height = data.height;
        this.field.data_table_columns[index].width = data.width;

        // Update the total width of the data table
        const allFieldsWith = this.getAllFieldsWidth();

        if (this.field.width < allFieldsWith) {
          this.field.width = allFieldsWith;
        }
      }
    },

    getXvalueForField(data_table_columns = [], index) {
      if (index == 0) {
        return 10;
      }

      let previousFields = data_table_columns.slice(0, index);
      var result = previousFields.reduce(function (acc, obj) {
        return obj.width;
      }, 0);
      return 30 + result;
    },
    getAllFieldsWidth() {
      let previousFields = this.field.data_table_columns;
      var result = previousFields.reduce(function (acc, obj) {
        return obj.width;
      }, 0);

      return result;
    },
  },
};
</script>

<style lang="scss">
.elementsScroll {
  height: 50px;
}
.paragraphScroll {
  height: 120px;
  max-height: 300px;
  overflow-x: auto;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.paragraphScroll::-webkit-scrollbar {
  height: 0.5em;
}
.paragraphScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.table-striped {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: nowrap;
  padding: 1.5rem;
  z-index: 1000;
  border: 10px;
  max-width: 1180px;
  height: 7rem;
  background-color: antiquewhite;
  overflow-x: auto;
  scrollbar-width: thin;
}

.resizable-wrapper {
  display: flex;
  justify-content: center;
}

.selection-options {
  display: flex;
  flex-direction: column;
}
</style>
